import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Consulting.css';

function Consulting() {
  const [mismatchedData, setMismatchedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const fetchMismatchedData = async () => {
    try {
      const [videResponse, importResponse] = await Promise.all([
        axios.get('https://apivide.c2i.tn:5002/api/vide/vide'),
        axios.get('https://apivide.c2i.tn:5002/api/vide/import'),
      ]);

      const videData = videResponse.data.map((row) => row.codeArt);
      const importData = importResponse.data.map((row) => row.LOCATION);

      const mismatched = videData.filter((codeArt) => !importData.includes(codeArt));
      setMismatchedData(mismatched);
      setError("");
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch data initially
    fetchMismatchedData();

    // Set up a polling interval for real-time updates
    const intervalId = setInterval(() => {
      fetchMismatchedData();
    }, 5000); // Fetch data every 5 seconds

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

  if (error) {
    return <div className="error-container">{error}</div>;
  }

  return (
    <div className="consulting-container">
      <button className="back-button" onClick={() => navigate("/")}>
        Back to Home
      </button>
      <h2 className="title">Consulting Data (Real-Time)</h2>
      {mismatchedData.length > 0 ? (
        <div className="mismatch-container">
          <p>The following items are mismatched:</p>
          <ul className="animated-list">
            {mismatchedData.map((codeArt, index) => (
              <li key={index} className="animated-item">
                {codeArt}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="match-container">
          <p>All data matches! 🎉</p>
        </div>
      )}
    </div>
  );
}

export default Consulting;
