import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import for navigation
import "./App.css";

function App() {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate(); // Hook for navigation

  const handleButtonClick = async () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".xlsx, .xls";

    input.onchange = async (event) => {
      const file = event.target.files[0];

      if (!file) {
        setErrorMessage("No file selected");
        return;
      }

      const validExtensions = [".xlsx", ".xls"];
      const fileExtension = file.name.slice(file.name.lastIndexOf(".")).toLowerCase();

      if (!validExtensions.includes(fileExtension)) {
        setErrorMessage("Please upload a valid Excel file (.xlsx, .xls)");
        setSuccessMessage("");
        return;
      }

      setErrorMessage("");
      setSuccessMessage("Uploading...");

      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await axios.post("https://apivide.c2i.tn:5002/api/vide/import-excel", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        setSuccessMessage("File uploaded and data imported successfully!");
        setErrorMessage("");
        console.log("Upload successful:", response.data);

        // Navigate to Consulting page
        navigate("/consulting");
      } catch (error) {
        console.error("Upload failed:", error);
        setErrorMessage("Failed to upload file. Please try again.");
        setSuccessMessage("");
      }
    };

    input.click();
  };

  return (
    <div className="app">
      <div className="Framed">
        <img src="/xls.gif" className="image" alt="Excel Icon" />
        <button onClick={handleButtonClick} className="upload-button">
          Import Excel File
        </button>
      </div>
      {errorMessage && (
        <div className="error-popup">
          <p>{errorMessage}</p>
        </div>
      )}
      {successMessage && (
        <div className="success-popup">
          <p>{successMessage}</p>
        </div>
      )}
    </div>
  );
}

export default App;
